<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <div>
          <h3 class="mb-0">
            Forgot Password
          </h3>
          <p>
            Enter your email address and we'll send you an email with instructions to reset your password.
          </p>
          <ValidationObserver ref="resetForm">
            <form class="mt-4">
              <div class="form-group">
                <label for="exampleInputEmail1">Email address</label>
                <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                  <input type="email" class="form-control mb-0" id="exampleInputEmail1" aria-describedby="emailHelp" v-model="userEmail" placeholder="Enter email">
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <div class="d-inline-block w-100">
                <button type="button" class="btn btn-primary float-right" @click="resetPassword">
                  Reset Password
                </button>
              </div>
            </form>
          </ValidationObserver>

          <div class="sign-info">
            <span class="dark-color d-inline-block line-height-2">Already Have An Account ? <router-link :to="{ name: 'auth.login'}">Sign In</router-link></span>
            <ul class="iq-social-media mt-4">
              <li>
                <a href="https://www.facebook.com/highschoolmomsgroup" target="_blank"><i class="ri-facebook-box-line"></i></a>
              </li>
              <li>
                <a href="https://twitter.com/hsminace" target="_blank"><i class="ri-twitter-line"></i></a>
              </li>
              <li>
                <a href="https://www.instagram.com/highschoolmoms" target="_blank"><i class="ri-instagram-line"></i></a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/highschoolmoms/" target="_blank"><i class="ri-linkedin-line"></i></a>
              </li>
            </ul>
          </div>

          <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
            <div v-html="toastMsg">
            </div>
          </b-toast>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<style>
  @import url("../assets/css/custom.css");
  @import url("../assets/css/PriceSlider.css");
</style>
<script>
import { User } from "../FackApi/api/user"
import ApiResponse from "../Utils/apiResponse.js"
import { ValidationProvider } from "vee-validate"
export default {
  name: "ForgotPassword",
  components: {
    ValidationProvider
  },
  data: () => ({
    showToast: false,
    toastMsg: null,
    toastVariant: "default",
    toastTitle: "Reset Password",
    userEmail: "",
    showPasswordField: false
  }),
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  mounted () {
    this.userEmail = this.$route.params.email
  },
  methods: {
    async resetPassword () {
      try {
        this.$store.dispatch("User/setUserProfileAction", { user_email: this.userEmail })
        let resp = await User.userForgotPassword(this, this.userEmail)
        await ApiResponse.responseMessageDisplay(this, resp)
        if (resp.resp_status) {
          setTimeout(() => {
            this.$router.push("/resetPassword")
          }, 500)
        }
      }
      catch (err) {
        console.error("Exception occurred in resetPassword() and Exception:", err.message)
      }
    }
  }
}
</script>
